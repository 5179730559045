.app-dashboard{
  background-color: #ffffff;
  overflow: hidden;
  .ant-layout-header,.ant-layout-sider,.ant-layout{
    background-color: #ffffff;
  }
  .ant-layout-header{
    padding: .7rem 0;
    height: auto;
  }
  .ant-layout-sider{
    border-right: 1px solid #f5f3f3;
  }
  .ant-layout-content{
    background-color: #f5f3f3;
    padding: 1rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      border: 1px solid #f5f3f3;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #00B7FF;
      border-radius: 4px;
      outline: none;
    }
  }

  .ant-layout-sider-trigger{
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    color: #00B7FF;
    border-top: 1px solid #f5f3f3;
    border-right: 1px solid #f5f3f3;
  }
}

//Login page
.app-login{
  .app-login-image{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 80%;
    }
  }
  .ant-card{
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-card-body{
      width: 100%;
    }
  }
}
.client{
  .ant-btn{
    &.active{
      background-color: rgba(24, 144, 255, 0.8);
      border-color: rgba(24, 144, 255, 0.8);
      color: #ffffff;
    }
  }
  .ant-layout-header{
    background-color: #ffffff;
    color:#030303;
  }
  .ant-layout-content{
    max-height: 100vh;
    overflow-y: auto;
  }
  .active-category{
    .ant-btn{
      background-color: #1890ff;
      color: #ffffff;
    }
  }
}
.ant-dropdown{
  box-shadow: 0 4px 4px rgba(0,0,0,0.08)!important;
}
.sun-editor{
  .sun-editor-editable{
    font:{
      family: 'Roboto',sans-serif!important;
      size: 16px;
    }
  }
}