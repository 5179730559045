@import "~antd/dist/antd.min.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,900&display=swap');
@import './pages';
body{
  font:{
    family: 'Roboto',sans-serif;
  }
}
h1,h2,h3,h4,h5,h6,strong,b{
  font-weight: 700;
}
$sizes: (
        0: 0,
        1:.25rem,
        2:.5rem,
        3:1rem,
        4:1.5rem,
        5:3rem
);
$text: left,right,center,justify;
@each $size, $value in $sizes{
  .m-#{$size}{
    margin: $value;
  }
  .mt-#{$size}, .my-#{$size}{
    margin-top: $value;
  }
  .mb-#{$size}, .my-#{$size}{
    margin-bottom: $value;
  }
  .mr-#{$size}, .mx-#{$size}{
    margin-right: $value;
  }
  .ml-#{$size}, .mx-#{$size}{
    margin-left: $value;
  }
  .p-#{$size}{
    padding: $value;
  }
  .pt-#{$size}, .py-#{$size}{
    padding-top: $value;
  }
  .pb-#{$size}, .py-#{$size}{
    padding-bottom: $value;
  }
  .pr-#{$size}, .px-#{$size}{
    padding-right: $value;
  }
  .pl-#{$size}, .px-#{$size}{
    padding-left: $value;
  }
}
@each $align in $text{
  .text-#{$align}{
    text-align: $align;
  }
}
/// Other class styles
.w-100{
  width: 100%;
}
.h-100{
  height: 100%;
}
.vh-100{
  height: 100vh;
}
.shadow{
  box-shadow: -8px 0 10px rgba(0,0,0,.05);
}
//Add style classes for ant
.ant-select{
  width: 100%;
}
.ant-btn-success{
  background-color: #34bf49;
  border-color: rgba(52, 191, 73, 0.8);
  color: #ffffff;
  &:hover{
    background-color: rgba(52, 191, 73, 0.8);
    color: #ffffff;
  }
}
.cursor-pointer{
  cursor: pointer;
}
.client{
  width: 100%;
  height: 100vh;
  .ant-card{
    &.active{
      color:#fff;
      background-color: #00B7FF;
    }
  }
}
.text-light{
  color: #ffffff;
}
.img-fluid{
  max-width: 100%;
}
.img-table{
  width: 45px;
  height: 45px;
  object-fit: cover;
}
